import clsx from 'clsx';
import React from 'react';

import './styles.css';

export interface ProductShowcaseProps
    extends React.HTMLAttributes<HTMLDivElement> {
    size?: 'sm' | 'lg';
    color?: 'bg-light-gray-100' | 'bg-primary-200' | 'bg-light-blue-100';
    withCircle?: boolean;
    img: string;
    alt: string;
}

const ProductShowcase = React.forwardRef<HTMLDivElement, ProductShowcaseProps>(
    ({ alt, className, color, img, size, withCircle, ...props }, ref) => {
        const classes = clsx(
            'flex items-center justify-center relative z-10',
            { 'h-30 min-h-25': size === 'sm' },
            { 'h-38 min-h-25': size === 'lg' },
            className
        );

        return (
            <div className={classes} ref={ref} {...props}>
                <img
                    src={img}
                    alt={alt}
                    className={clsx('max-h-full relative z-10')}
                />
                {withCircle && (
                    <div
                        className={clsx(
                            'product-showcase-circle',
                            { 'h-20 w-20 min-w-20 min-h-20': size === 'sm' },
                            { 'h-30 w-30 min-w-20 min-h-20': size === 'lg' },
                            'absolute rounded-full',
                            color
                        )}
                    />
                )}
            </div>
        );
    }
);

ProductShowcase.defaultProps = {
    size: 'sm',
    color: 'bg-light-gray-100',
    withCircle: true
};

export default ProductShowcase;
