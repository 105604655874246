import clsx from 'clsx';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Grid } from '../../components/layout/grid';
import { SEO } from '../../components/seo';
import { Heading } from '../../components/typography/heading';
import { Paragraph } from '../../components/typography/paragraph';
import { Box } from '../../components/wrapper/box';
import { ProductShowcase } from '../../ui/product/product-showcase';
import { WithSidebarLayout } from '../../ui/with-sidebar-layout';

interface ProductsTemplateProps {
    data: any;
}

const ProductsTemplate: FC<ProductsTemplateProps> = ({ data }) => {
    const { title, products } = data.category;
    const { subMenu, seo } = data.allCategory;

    return (
        <>
            <SEO
                title={title}
                description={seo.description}
                image={seo.image.file.url}
            />

            <WithSidebarLayout sidebarNav={subMenu}>
                <Grid item>
                    <div
                        className={clsx(
                            'mb-4 t-sm:flex t-sm:items-end t-sm:justify-between'
                        )}>
                        <Heading
                            tagName="h1"
                            className="text-lg mb-0 max-w-24ch">
                            {title}
                        </Heading>

                        <Paragraph className="mt-2 m-lg:mt-0 leading-normal">
                            Total Products:
                            <span className="ml-1 font-medium">
                                {products.length}
                            </span>
                        </Paragraph>
                    </div>

                    <Grid
                        item
                        container
                        defaultGap={false}
                        base={false}
                        className={clsx(
                            'gap-y-8',
                            't-sm:grid-cols-2 t-sm:gap-x-2 t-sm:content-start',
                            'l-sm:grid-cols-3'
                        )}>
                        {products.map(
                            (item: {
                                id: string;
                                name: string;
                                image: {
                                    file: {
                                        url: string;
                                    };
                                    description: string;
                                };
                            }) => {
                                return (
                                    <Grid item key={item.id}>
                                        <Box
                                            base={false}
                                            ratio="1:1"
                                            className="bg-light-gray mb-2 p-4">
                                            <ProductShowcase
                                                img={
                                                    item.image
                                                        ? item.image.file.url
                                                        : ''
                                                }
                                                alt={
                                                    item.image
                                                        ? item.image.description
                                                        : ''
                                                }
                                                withCircle={false}
                                            />
                                        </Box>

                                        <Heading
                                            tagName="h5"
                                            className="max-w-28ch">
                                            {item.name}
                                        </Heading>
                                    </Grid>
                                );
                            }
                        )}
                    </Grid>
                </Grid>
            </WithSidebarLayout>
        </>
    );
};

export default ProductsTemplate;

export const query = graphql`
    query($slug: String!) {
        category: contentfulProductsPageSubCategory(slug: { eq: $slug }) {
            title
            slug
            products {
                id
                name
                image {
                    file {
                        url
                    }
                    description
                }
            }
        }
        allCategory: contentfulRoutesProducts {
            seo {
                description
                image {
                    file {
                        url
                    }
                }
            }
            subMenu {
                id
                title
                subCategories {
                    id
                    title
                    slug
                }
            }
        }
    }
`;
